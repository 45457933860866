import { useEffect, useRef } from 'react';

import { AccountApi, EventsApi } from '@shared/api/baseQuerys';
import { QueryKeys } from '@shared/const/storageKeys';
import { useConfiguredQuery } from '@shared/hooks/useConfiguredQuery';
import { appStore } from '@shared/model/app_store/AppStore';

export const useNavBarApi = () => {
  const { selectedBranchState } = appStore((state) => state);
  const {
    data,
    isLoading: isLoadingAccountData,
    error,
    refetch: refetchAccountData,
  } = useConfiguredQuery([QueryKeys.ACCOUNT], AccountApi.getAccountData, {
    settings: {
      networkMode: 'offlineFirst',
    },
    triggerOnBranchChange: false,
  });

  // Используем useRef, чтобы хранить интервал и избежать утечки памяти
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const isCreatePermission = appStore((state) =>
    state.permissions?.includes('PERMISSION_SERVICE_MODE_CREATE'),
  );

  const { data: count, refetch: refetchCount } = useConfiguredQuery(
    [QueryKeys.AUTO_SERVICE_COUNT_EVENTS_LIST],
    isCreatePermission ? EventsApi.getEventListCountForAutoServiceURL : undefined,
    {
      options: isCreatePermission
        ? { filterOptions: { branchId: selectedBranchState?.id } }
        : undefined,
    },
  );
  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        refetchCount();
      }, 10000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [refetchCount]);

  return {
    refetchAccountData,
    userData: data?.data,
    isLoadingAccountData,
    length: count?.data || 0,
    error,
  };
};
