import React, { useState } from 'react';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddIcon from '@mui/icons-material/Add';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import { CustomPaginationActions } from './CustomPaginationActions';
import { EmailTemplateForm } from './EmailTemplateForm';
import { UserInfo } from './EmailTemplatesPage';

interface EmailTemplate {
  content: string;
  id: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type?: any;
  createdBy: UserInfo;
  createdAt: string;
  lastModifiedBy?: UserInfo;
  EmailTemplate: string;
  lastModifiedAt?: string;
  actual: boolean;
  templateType?: {
    id: number;
    type: string;
    name: string;
  };
}

interface EmailTemplatesGridProps {
  onToggleStatus: (id: number) => void;
  template?: EmailTemplate;
  templates?: EmailTemplate[];
  onCreate: (template: Omit<EmailTemplate, 'id' | 'createdBy' | 'createdAt'>) => void;
  onEditSave: (template: Partial<EmailTemplate>) => void;
  onDelete: (id: number) => void;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const EmailTemplatesGrid: React.FC<EmailTemplatesGridProps> = ({
  templates,
  onToggleStatus,
  onCreate,
  onEditSave,
  onDelete,
  totalCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const [openEditor, setOpenEditor] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<EmailTemplate | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<EmailTemplate | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Состояние сортировки
  const [orderBy, setOrderBy] = useState<keyof EmailTemplate | null>(null);
  const [order, setOrder] = useState<'asc' | 'desc' | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<keyof EmailTemplate | null>(null);

  const filteredTemplates =
    templates?.filter((template) =>
      template.name.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || [];

  const handleOpenEditorForCreate = () => {
    setEditingTemplate(null);
    setOpenEditor(true);
  };

  const handleOpenEditorForEdit = (template: EmailTemplate) => {
    setEditingTemplate(template);
    setOpenEditor(true);
  };

  const handleOpenDeleteDialog = (template: EmailTemplate) => {
    setTemplateToDelete(template);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (templateToDelete) {
      onDelete(templateToDelete.id);
    }
    setDeleteDialogOpen(false);
    setTemplateToDelete(null);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onRowsPerPageChange(event);
    onPageChange(null, 0);
  };

  // Функция для обработки клика по заголовку колонки и смены направления сортировки
  const handleRequestSort = (property: keyof EmailTemplate) => {
    if (orderBy === property) {
      setOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : prevOrder === 'desc' ? null : 'asc'));
      if (order === 'desc') {
        setOrderBy(null);
      }
    } else {
      setOrderBy(property);
      setOrder('asc');
    }
  };

  // Функция сортировки
  const sortedTemplates = [...filteredTemplates].sort((a, b) => {
    if (!orderBy) return 0;

    const valueA = a[orderBy] ?? '';
    const valueB = b[orderBy] ?? '';

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }
    return 0;
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '98vh' }}>
      <TextField
        variant="outlined"
        placeholder="Поиск"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        size="small"
        sx={{ marginBottom: 2, width: '150vh' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchQuery('')} edge="end" size="small">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', height: '96vh' }}>
        <TableContainer sx={{ flexGrow: 1, maxHeight: '92vh' }}>
          <Table size="small" stickyHeader sx={{ tableLayout: 'fixed', minWidth: '1000px' }}>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#f5f5f5',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}>
                {[
                  { label: 'Название', field: 'name' },
                  { label: 'Автор', field: 'createdBy' },
                  { label: 'Дата создания', field: 'createdAt' },
                  { label: 'Тип шаблона', field: 'templateType' },
                  { label: 'Дата изменения', field: 'lastModifiedAt' },
                ].map(({ label, field }) => (
                  <TableCell
                    key={field}
                    sx={{ fontWeight: 'bold', backgroundColor: '#dad8d8', cursor: 'pointer' }}
                    onClick={() => handleRequestSort(field as keyof EmailTemplate)}
                    onMouseEnter={() => setHoveredColumn(field as keyof EmailTemplate)}
                    onMouseLeave={() => setHoveredColumn(null)}>
                    {label}
                    {(orderBy === field && order !== null) ||
                    (order === null && hoveredColumn === field) ? (
                      <IconButton size="small" sx={{ ml: 1 }}>
                        {order === 'asc' ? (
                          <ArrowUpwardIcon sx={{ fontSize: '1.5rem' }} />
                        ) : order === 'desc' ? (
                          <ArrowDownwardIcon sx={{ fontSize: '1.5rem' }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ opacity: 0.5, fontSize: '1.5rem' }} />
                        )}
                      </IconButton>
                    ) : null}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    width: '120px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    backgroundColor: '#dad8d8',
                  }}>
                  Действия
                </TableCell>
                <TableCell
                  sx={{
                    width: '60px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    backgroundColor: '#dad8d8',
                  }}>
                  <IconButton onClick={handleOpenEditorForCreate} sx={{ color: 'black' }}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTemplates.map((template) => (
                <TableRow
                  key={template.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                  }}>
                  <TableCell>{template.name}</TableCell>
                  <TableCell>
                    {template.createdBy?.surname || template.createdBy?.firstName || '—'}
                  </TableCell>
                  <TableCell>{template.createdAt}</TableCell>
                  {/* <TableCell>
                    {template.lastModifiedBy?.surname || template.lastModifiedBy?.firstName || '—'}
                  </TableCell> */}
                  <TableCell>
                    {template.templateType ? template.templateType.name : 'Загрузка...'}
                  </TableCell>
                  <TableCell>{template.lastModifiedAt || '—'}</TableCell>
                  <TableCell
                    sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title={template.actual ? 'Активный' : 'Неактивный'}>
                      <span>
                        {' '}
                        {/* Обёртка span нужна, если кнопка может быть disabled */}
                        <IconButton
                          color={template.actual ? 'success' : 'primary'}
                          disabled={template.actual} // Доступна для клика только если actual=false
                          onClick={() => onToggleStatus(template.id)}>
                          {template.actual ? <AddTaskIcon /> : <AcUnitIcon />}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={'Редактирование'}>
                      <IconButton
                        onClick={() => handleOpenEditorForEdit(template)}
                        color="inherit"
                        disabled={template.createdBy?.id === 0}>
                        <ModeEditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Удаление'}>
                      <IconButton
                        onClick={() => handleOpenDeleteDialog(template)}
                        color="inherit"
                        disabled={template.createdBy?.id === 0}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* Пагинация */}
      <Paper sx={{ borderTop: '1px solid #ccc' }}>
        <TablePagination
          sx={{
            marginTop: 'auto',
            backgroundColor: 'transparent',
            borderTop: 'none',
          }}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          ActionsComponent={CustomPaginationActions}
        />
      </Paper>
      {/* Окно редактора */}
      <Dialog open={openEditor} onClose={() => setOpenEditor(false)} maxWidth="lg" fullWidth>
        <EmailTemplateForm
          template={editingTemplate ?? undefined}
          onSave={editingTemplate ? onEditSave : onCreate}
          onClose={() => setOpenEditor(false)}
        />
      </Dialog>

      {/* Модальное окно подтверждения удаления */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '16px',
            padding: '10px',
            height: '220px',
          },
        }}>
        <DialogTitle
          sx={{
            color: 'black',
            fontWeight: '900',
            fontSize: '20px',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
          }}>
          Подтверждение удаления
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'black', textAlign: 'start', fontSize: '18px' }}>
            Вы действительно хотите удалить шаблон {templateToDelete?.name} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            color="inherit"
            sx={{
              width: '100px',
              borderRadius: '6px',
              border: '1px solid #494646',
              marginRight: '16px',
            }}>
            Нет
          </Button>
          <Button
            onClick={handleDeleteConfirmed}
            color="inherit"
            sx={{ width: '100px', borderRadius: '6px', border: '1px solid #494646' }}>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
