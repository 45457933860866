import React, { useEffect, useState } from 'react';

import { Box, Button, MenuItem, TextField } from '@mui/material';

import { TemplatesApi } from '@shared/api/baseQuerys';
import { Editor } from '@tinymce/tinymce-react';

import { EmailTemplate } from './EmailTemplatesPage';

interface EmailTemplateFormProps {
  template: EmailTemplate | null;
  onSave: (
    template: Partial<EmailTemplate> | Omit<EmailTemplate, 'id' | 'createdBy' | 'createdAt'>,
  ) => void;
  onClose: () => void;
}

export const EmailTemplateForm: React.FC<EmailTemplateFormProps> = ({
  template,
  onSave,
  onClose,
}) => {
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [templateType, setTemplateType] = useState('');
  const [templateTypes, setTemplateTypes] = useState<{ id: number; type: string; name: string }[]>(
    [],
  );

  useEffect(() => {
    if (template) {
      setName(template.name);
      setContent(template.content);
      setTemplateType(template.type || templateTypes[0]?.type || '');
    }
  }, [template, templateTypes]);

  useEffect(() => {
    const fetchTemplateTypes = async () => {
      try {
        const response = await TemplatesApi.getTemplateTypes();
        const data = response.data; // Извлекаем данные из объекта ответа
        if (Array.isArray(data)) {
          setTemplateTypes(data); // Теперь data — это массив, и можно безопасно обновить состояние
        } else {
          // eslint-disable-next-line no-console
          console.error('Неверный формат данных', data);
          setTemplateTypes([]); // На случай, если данные не массив
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Ошибка при загрузке типов шаблонов:', error);
      }
    };

    fetchTemplateTypes();
  }, []);

  const handleSave = () => {
    if (!name?.trim() || !content?.trim() || !templateType?.trim()) {
      alert('Заполните все поля!');
      return;
    }

    const selectedTemplateType = templateTypes.find((t) => t.type === templateType);
    if (!selectedTemplateType) {
      alert('Выбран неверный тип шаблона!');
      return;
    }

    const newTemplate = {
      name,
      content,
      templateType: { id: selectedTemplateType.id }, // ✅ Передаём объект
    };

    if (template) {
      //@ts-expect-error: временное решение
      onSave({ id: template.id, ...newTemplate });
    } else {
      //@ts-expect-error: временное решение
      onSave(newTemplate);
    }

    onClose();
  };

  return (
    <Box sx={{ p: 1, width: '100%', maxWidth: 'none', display: 'flex', flexDirection: 'column' }}>
      <TextField
        label="Название шаблона"
        fullWidth
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <TextField
        label="Тип шаблона"
        fullWidth
        margin="normal"
        select
        value={templateType}
        onChange={(e) => setTemplateType(e.target.value)}>
        {templateTypes.map((type) => (
          <MenuItem key={type.id} value={type.type}>
            {type.name}
          </MenuItem>
        ))}
      </TextField>

      <Editor
        apiKey="bgz75tshd3764oezi1hqxeyqeugcwoqbwntl8vzrcuhk7d7x"
        value={content}
        init={{
          plugins: 'code image',
          image_title: true,
          automatic_uploads: true,
          toolbar: 'undo redo | bold italic | code image',
          image_advtab: true,
          valid_elements: '*[*]',
          extended_valid_elements: 'img[src|alt|width|height|style]',
          images_upload_url: '',
          forced_root_block: '',
          file_picker_types: 'image',
          images_upload_credentials: true,
          height: 400,
          language: 'ru',
          width: '100%',
          file_picker_callback: (callback, value, meta) => {
            if (meta.filetype === 'image') {
              const input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.onchange = function () {
                const file = (this as HTMLInputElement).files?.[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = function (e) {
                    callback(e.target?.result as string, { alt: file.name });
                  };
                  reader.readAsDataURL(file);
                }
              };
              input.click();
            }
          },
        }}
        onEditorChange={(newContent) => setContent(newContent)}
      />

      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {template ? 'Сохранить изменения' : 'Создать шаблон'}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Отменить
        </Button>
      </Box>
    </Box>
  );
};
