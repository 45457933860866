import { Link, NavLink } from 'react-router-dom';

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Button, Stack, Switch, Tooltip, Typography } from '@mui/material';

import { MenuButton } from '@features/menu_button';
import { NavbarBranchSelect } from '@features/nav_bar_branch_select';
import { PasswordForm } from '@features/password_form';
import { AccountApi } from '@shared/api/baseQuerys';
import { RoutePaths } from '@shared/config/routePathsEnum';
import { QueryKeys, StorageKeys } from '@shared/const/storageKeys';
import { testids } from '@shared/const/testid';
import { useConfiguredQuery } from '@shared/hooks/useConfiguredQuery';
import { useLocalStorage } from '@shared/hooks/useLocalStorage';
import { useToggle } from '@shared/hooks/useToggle';
import { Logo } from '@shared/images/logo';
import { appStore } from '@shared/model/app_store/AppStore';
import { Popup } from '@shared/ui/popup';

import { NAV_LINKS, frontendVersion } from '../config/const';
import { tooltipStyle } from '../config/styles';
import { useNavBar } from '../hooks/useNavBar';
import style from './NavBar.module.scss';

export const NavBar = () => {
  const [open, toggle, close] = useToggle();
  const { state, setItemState } = useLocalStorage({
    key: StorageKeys.NAVBAR_COLLOPS_MENU,
    value: false,
  });
  const [isSliderActive, setSliderActive] = useToggle(true); // Стейт слайдера
  const { length, permissionsFilter, email } = useNavBar();
  const handleCollops = () => {
    setItemState(!state);
  };

  const { data } = useConfiguredQuery([QueryKeys.BACKEND_VERSION], AccountApi.getBackandVersion, {
    triggerOnBranchChange: false,
  });

  const backendVersion = data?.data as string;
  const isAdmin = appStore().isAdmin;

  return (
    <>
      <div className={`${state ? style.navBarCollops : style.navBarOpen} ${style.wrapper}`}>
        <div className={`${style.logo} ${!state && style.between}`}>
          <Link to="/events">
            <Logo className={style.img} />
          </Link>
          {!state && <span className={style.logoText}>Лазерные системы</span>}
        </div>
        <div className={`${state ? style.navBarCollops : style.navBarOpen} ${style.navBar}`}>
          <div className={style.navBarWrapper}>
            <NavbarBranchSelect
              tooltipProps={{ slotProps: tooltipStyle, placement: 'right' }}
              isCollops={state}
            />

            <div className={style.links}>
              {NAV_LINKS.filter(permissionsFilter).map((link, i) => {
                // Если слайдер активен, показываем все вкладки, кроме "Шаблоны сообщений"
                if (isSliderActive && link.path === RoutePaths.messages) {
                  return null; // Скрываем "Шаблоны сообщений" при активном слайдере
                }

                // Если слайдер неактивен, показываем только "Шаблоны сообщений"
                if (!isSliderActive && link.path !== RoutePaths.messages) {
                  return null; // Скрываем все вкладки, кроме "Шаблоны сообщений", если слайдер неактивен
                }

                // Отображаем счётчик заявок (красненький кружочек) во вкладке автосервис:
                const notification = link.path === RoutePaths.autoService;

                return (
                  <Tooltip
                    placement="right"
                    slotProps={tooltipStyle}
                    disableHoverListener={!state}
                    title={link.name}
                    key={link.path}>
                    <NavLink
                      key={link.path}
                      data-testid={testids.widget_navbar.NAVBAR_LINK[i]}
                      className={`${state ? style.center : style.between} ${style.navLink}`}
                      to={link.path}>
                      {!state && <span>{link.name}</span>}
                      <span>{link.icon}</span>
                      {notification && <span className={style.notifications}>{length}</span>}
                    </NavLink>
                  </Tooltip>
                );
              })}
            </div>
          </div>
          <div>
            {isAdmin && (
              <div className={style.sliderContainer} style={{ marginBottom: '16px' }}>
                <Typography variant="body2">Переключить вкладки</Typography>
                <Switch
                  checked={isSliderActive}
                  onChange={setSliderActive}
                  name="toggleTabs"
                  color="primary"
                />
              </div>
            )}
            {!state && (
              <div className={style.versionContainer}>
                <div className={style.versionItem}>
                  <Typography variant="inherit" className={style.versionText}>
                    frontend: v {frontendVersion}
                  </Typography>
                </div>
                <div className={style.versionItem}>
                  <Typography variant="inherit" className={style.versionText}>
                    backend: v {backendVersion}
                  </Typography>
                </div>
              </div>
            )}
            <Stack gap={1}>
              <MenuButton
                tooltipProps={{ slotProps: tooltipStyle, placement: 'right' }}
                collops={state}
                email={email}
                close={close}
                toggleModal={toggle}
              />
              <Button
                onClick={handleCollops}
                className={`${style.navBarButton} ${!state && style.between}`}>
                {!state && (
                  <span className={`${state && style.textCollops} ${style.text}`}>Скрыть</span>
                )}
                <span className={`${state && style.collops} ${style.openedCollops}`}>
                  <ArrowBackIosNewOutlinedIcon />
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </div>
      <Popup
        isOpen={open}
        toggleModal={toggle}
        closeonClickSpace={false}
        body={<PasswordForm close={close} />}
        onCloseModal={close}
      />
    </>
  );
};
