/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { TemplatesApi } from '@shared/api/baseQuerys';

import { EmailTemplatesGrid } from './EmailTemplatesGrid';

export interface UserInfo {
  id: number;
  email: string;
  firstName: string;
  surname?: string;
  middleName?: string;
}

export interface EmailTemplate {
  type?: string;
  id: number;
  name: string;
  content: string;
  createdBy: UserInfo | null;
  createdAt: string;
  lastModifiedBy?: UserInfo | null;
  lastModifiedAt?: string;
  actual: boolean;
  templateType?: { type: string };
}

interface EmailTemplatesResponse {
  content: EmailTemplate[];
  totalElements: number;
}

const EmailTemplatesPage: React.FC = () => {
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const formatDate = (date: string | undefined): string =>
    date
      ? new Date(date)
          .toLocaleString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })
          .replace(',', '')
      : '—';

  const fetchTemplates = () => {
    TemplatesApi.getTemplates({ page, size: rowsPerPage })
      //@ts-expect-error: Временное решение
      .then(({ data }: { data: EmailTemplatesResponse }) => {
        if (Array.isArray(data?.content)) {
          const formattedTemplates: EmailTemplate[] = data.content.map((item) => ({
            type: item.templateType?.type ?? '',
            templateType: item.templateType ?? null,
            id: item.id,
            name: item.name,
            content: item.content,
            createdBy: item.createdBy ?? null,
            createdAt: formatDate(item.createdAt),
            lastModifiedBy: item.lastModifiedBy ?? null,
            lastModifiedAt: formatDate(item.lastModifiedAt),
            actual: !!item.actual,
          }));
          setTemplates(formattedTemplates);
          setTotalCount(data.totalElements); // Нужно получать значение от бэка
        } else {
          // console.error('Ошибка: API вернуло не массив:', data);
          setTemplates([]);
        }
      })
      .catch(() => {
        setTemplates([]);
      });
  };

  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const toggleStatus = async (id: number) => {
    try {
      await TemplatesApi.toggleTemplateActual(id);
      fetchTemplates(); // Перезагружаем шаблоны
    } catch (error) {
      console.error('Ошибка при изменении статуса шаблона:', error);
    }
  };

  const handleCreate = (template: Omit<EmailTemplate, 'id'>) => {
    TemplatesApi.createTemplate(template).then(fetchTemplates);
  };

  const handleSave = (updatedTemplate: Partial<EmailTemplate>) => {
    TemplatesApi.updateTemplate(updatedTemplate).then(() => {
      fetchTemplates();
    });
    // .catch((error) => console.error('Ошибка обновления шаблона:', error));
  };

  const handleDelete = (id: number) => {
    TemplatesApi.deleteTemplate(id).then(() => fetchTemplates());
    // .catch((error) => console.error('Ошибка удаления шаблона:', error));
  };

  return (
    <Box sx={{ p: 3 }}>
      <EmailTemplatesGrid
        //@ts-expect-error: Временное решение
        templates={templates}
        onEditSave={handleSave}
        onDelete={handleDelete}
        //@ts-expect-error: Временное решение
        onCreate={handleCreate}
        totalCount={totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => setRowsPerPage(+event.target.value)}
        onToggleStatus={toggleStatus}
      />
    </Box>
  );
};

export default EmailTemplatesPage;
